.notFound {
  background-color: #121212;
  width: 100vw;
  height: 100vh;
  color: white;
}
.page-notfound-btn {
  background-color: white !important;
  color: black !important;
}
.page-notfound-btn:hover {
  scale: 1.3;
}
.go-back-btn {
  color: white !important;
}
