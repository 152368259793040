.flowDelayModal {
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.flowDelayModal .MuiFormControl-root {
  margin-bottom: 16px;
}

.flowDelayModal .MuiTextField-root {
  margin-top: 8px;
}

.flowDelayModal .MuiSelect-root:focus {
  border: none;
  box-shadow: none;
}

.timepicker .MuiSelect-root {
  margin: 16px 0;
  padding: 16px 0;
  width: 80px;
  background-color: yellow;
}
