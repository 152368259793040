.workspace {
  &__page {
    height: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: center !important;
    max-width: 1200px !important;
  }

  &__modal {
    backdrop-filter: none;
    position: relative;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    overflow: hidden;
    width: 310px;
    max-height: 700px !important;
    z-index: 1000;
    overflow-y: scroll;
    position: absolute;
    left: -10px;
    top: -14px;
    padding: 10px;
    // padding: 20px !important;
    &__element {
      user-select: none;
      transition: background 20ms ease-in 0s;
      cursor: pointer;
      display: flex;
      align-items: center;
      line-height: 120%;

      &:hover {
        background-color: rgba(255, 255, 255, 0.5);

        .opacity-0 {
          opacity: 1 !important;
        }
      }
    }
  }

  &__cont {
    overflow-y: scroll;
  }

  &__modal-cont {
    // max-height: 90vh;
    height: 100%;
  }

  &__page-cont {
    max-width: 900px;
  }

  &__element {
    padding: 12px;

    &__text {
      position: relative;
    }

    &:hover {
      background-color: lighten(#2f2f2f, 10%);

      .opacity-0 {
        opacity: 1 !important;
      }
    }
  }
}

.select_workspace {
  position: relative;
  max-width: 100% !important;
}

.overlay {
  position: absolute;
  /* Positioning and size */
  top: 75%;
  left: 100%;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}

.overlay_home {
  position: absolute;
  /* Positioning and size */
  top: 75%;
  left: 100%;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}

.selected-org {
  background-color: #888 !important;
}

.footer {
  // background-color: rgb(251, 250, 249);
  padding-top: 6px;
  padding-bottom: 6px;
  box-shadow: rgba(55, 53, 47, 0.09) 0px -1px 0px;
  margin-top: 1px;
  // color: rgba(55, 53, 47, 0.65);
  white-space: nowrap;
  font-size: 12px;

  /* Corrected Typography hover effect */
  .truncated-text {
    &:hover {
      background-color: lighten(#2f2f2f, 10%);

      .opacity-0 {
        opacity: 1 !important;
      }
    }
  }
}
.bg-org-selected {
  background-color: #454545;
}
