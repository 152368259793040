.Mui-expanded {
  .accordion-typo {
    background-color: transparent;
    font-size: 14px !important;
  }
}

.smallHeadinghere {
  text-transform: uppercase !important;
}

#panel1-header {
  min-height: 30px !important;
}

.dh-accordian-sidebar div {
  margin: 0px 0px 0px 3px !important;
}

@media screen and (max-width: 576px) and (min-width: 320px) {
  .help-options-dialog {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

@media screen and (max-width: 992px) and (min-width: 576px) {
  .help-options-dialog {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
.selected-workspace {
  z-index: 100 !important;
}
.selected-workspace:hover {
  background-color: var(--col-dark-grey-background) !important;
}
