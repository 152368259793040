// utility classes
.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.transparent {
  background-color: transparent !important;
}

.w-200px {
  width: 200px !important;
}

.w-250px {
  width: 250px !important;
}

.w-450px {
  width: 450px !important;
}

.w-700px {
  width: 700px !important;
}

.mxw-100px {
  max-width: 100px !important;
  width: fit-content !important;
}

.max-w-1000 {
  max-width: 1000px !important;
}

// height

.h-100vh {
  height: 100vh !important;
}

.h-18vh {
  height: 18vh;
}

.h-82vh {
  height: 82vh;
}

.h-0 {
  height: 0px !important;
}

/* width classes */
.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-40 {
  width: 40% !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}

.w-60 {
  width: 60% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

/* width classes */

.w-75vw {
  width: 75vw;
}

.w-100vh {
  height: 100vh;
}

.w-100vw {
  width: 100vw !important;
}

.w-30vw {
  width: 30vw !important;
}

.w-50vw {
  width: 50vw !important;
}

.w-50px {
  height: 50px !important;
}

.mt-50px {
  margin-top: 50px !important;
}

.w-maxContent {
  width: max-content !important;
}

.thintitle {
  font-weight: 10 !important;
  opacity: 0.7 !important;
}

.opacity-0-7 {
  opacity: 0.7 !important;
}

/* height classes */
.h-100 {
  height: 100% !important;
}

.min-h-100 {
  min-height: 100vh !important;
}
// .min-w-250 {
//   min-width: 250px !important;
// }
.h-4vh {
  height: 4vh !important;
}

.ml-6vw {
  margin-left: 6vw !important;
}

.mt-5vh {
  margin-top: 5vh !important;
}

.h-90vh {
  height: 90vh !important;
}

.h-3vh {
  height: 3vh !important;
}

.h-35vh {
  height: 30vh !important;
}

.mt-2vh {
  margin-top: 2vh !important;
}

.h-20vh {
  height: 20vh !important;
}

.h-30vh {
  height: 30vh !important;
}

.h-fitContent {
  height: fit-content !important;
}

.h-7vh {
  height: 7vh !important;
}

.underlineonhover:hover {
  text-decoration: underline;
  cursor: pointer;
}

.w-inherit {
  width: inherit !important;
}

.minHeight-8vh {
  min-height: 8vh !important;
}

/* Border classes */
.border-none {
  border: none !important;
}

.border-p5 {
  border: 0.5px solid #161616 !important;
}

.border-1 {
  border: 1px solid rgba(233, 231, 226, 1) !important;
}

.border-2 {
  border: 2px solid #161616 !important;
}

.border-3 {
  border: 3px solid #161616 !important;
}

.disable-border {
  border: 1px solid rgb(160, 159, 159) !important;
}

.error-border {
  border: 1px solid var(--col-error) !important;
}

/* color classes */

.color-red {
  color: red !important;
}

.color-F19F03 {
  color: #f19f03;
}

.color-green {
  color: green;
}

.bg_discard {
  background-color: #f9eae5 !important;
  color: black !important;
}

.color-blue {
  color: blue !important;
}

.bg-notification-blue {
  background-color: #0c8ce9;
}

.color-grey {
  color: grey;
}

.color-black {
  color: black;
}

.color-white {
  color: white;
}

/* background-color classes */
.bg-white {
  background-color: white !important;
}

.bg-F7F7F7 {
  background-color: #f7f7f7;
}

.bg-lightgreen {
  background-color: var(--col-primary-20);
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-black {
  background-color: black !important;
}

.bg-green {
  background-color: green !important;
}

.bg-pink {
  background-color: #f2acb9 !important;
}

.bg-blue {
  background-color: #1574bb !important;
}

.bg-red {
  background-color: red !important;
}

.bg-grey-white {
  background-color: #f7f7f7 !important;
}

.bg-F7F7F5 {
  background-color: #f7f7f5 !important;
}

.bg-EBEBEA {
  background-color: #ebebea !important;
}

.bg-F5F5F4 {
  background-color: #f5f5f4 !important;
}

.bg-grey {
  background-color: grey;
}

.bg-gray-white-medium {
  background-color: #e0e0e0 !important;
}

//
/* overflow classes */
.overflow-hide {
  overflow: hidden !important;
}

.overflow-hide-y {
  overflow-y: hidden !important;
}

.overflow-hide-x {
  overflow-x: hidden !important;
}

.overflow-scroll-x {
  overflow-x: scroll !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-scroll-y {
  overflow-y: scroll !important;
}

// .overflow-auto {
//   overflow: auto !important;
// }

/* font classes */
.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-30 {
  font-size: 30px !important;
}

.font-weight-7 {
  font-weight: 700 !important;
}

.font-bold {
  font-weight: bold !important;
}

.errorcolor {
  color: #a51226 !important;
}

/* text classes */
.underline {
  text-decoration: underline !important;
}
.dotted-underline {
  text-decoration: underline dotted !important;
  text-underline-offset: 3px !important; // this is for timezone in cron adjust if needed
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-justify {
  text-align: justify !important;
}

.text-left {
  text-align: left !important;
}

.no-texttransform {
  text-transform: none !important;
}

.captilize-texttransform {
  text-transform: capitalize !important;
}

.no-textdecoration {
  text-decoration: none !important;
}

.light-bottom-border {
  border-bottom: 0.1px solid var(--col-dark);
}

.border-bottom {
  border-bottom: 1px solid #161616 !important;
}

.w-fitcontent {
  width: fit-content !important;
}

.small {
  width: var(--width-small) !important;
}

.medium {
  width: var(--width-medium) !important;
}

.large {
  width: var(--width-large) !important;
}

.w-10vw {
  width: 10vw !important;
}

.w-15vw {
  width: 15vw !important;
}

.w-12vw {
  width: 12vw !important;
}

.w-22vw {
  width: 22vw !important;
}

.w-17vw {
  width: 17vw !important;
}

.w-17 {
  width: 326px !important;
}

.w-46vw {
  width: 46vw !important;
}

.cursor-grab {
  cursor: grab;
}

.pointer-event-none {
  pointer-events: none !important;
}

.text-overflow-eclipse {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.transform-360 {
  transform: rotate(270deg) !important;
  transition: transform 1s;
  /* Optional - adds a smooth transition effect */
}

.hidden {
  height: 0px;
  overflow: hidden;
}

.transform-180 {
  transform: rotate(180deg) !important;
  transition: transform 1s;
  /* Optional - adds a smooth transition effect */
}

.visible {
  height: auto;
}

.box-sizing-border-box {
  box-sizing: border-box !important;
}

/* position classes */
// position

.onhover {
  background-color: transparent;
}
.onhover:hover {
  background-color: rgb(226, 226, 226);
}

.position-fixed {
  position: fixed !important;
}

.pos-rel {
  position: relative !important;
}

.pos-abs {
  position: absolute !important;
}

.align-content-center {
  align-content: center !important;
}

.pos-stick {
  position: sticky;
}

.pos-fix {
  position: fixed;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100 !important;
}

.right-50 {
  right: 50% !important;
}

.left-50 {
  left: 50% !important;
}

.transform-center {
  transform: translate(-50%, -50%) !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.word_break-all {
  word-break: break-all;
}

/* opacity*/

.opacity-0 {
  opacity: 0 !important;
}

// .opacity-08 {
//   opacity: 0.8 !important;
// }

.opacity-06 {
  opacity: 0.6 !important;
}

.opacity-1 {
  opacity: 1 !important;
}

.zindex-20 {
  z-index: 20 !important;
}

.dis-none {
  width: 0 !important;
  padding: 0 !important;
  overflow-x: hidden !important;
}

.transition {
  transition: all 0.1s ease-in-out !important;
}

.transition-300 {
  transition: all 0.1s ease-in-out !important;
  transition-duration: 300ms !important;
}

.dis-initial {
  display: initial !important;
}

.w-0 {
  width: 0px !important;
}

.dis-contents {
  display: contents;
}

.text-link {
  color: var(--col-link) !important;
  text-decoration: underline !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.opacity-half {
  opacity: 0.5 !important;
}

.success-color {
  color: var(--col-success) !important;
}

.error-color {
  color: var(--col-error) !important;
}

.warning-color {
  color: var(--col-warning) !important;
}

.float-right {
  float: right !important;
}

.no-wrap {
  white-space: nowrap !important;
}

.align-end {
  align-self: flex-end;
}

.warning-bg {
  background-color: var(--col-warning) !important;
}

.new-button {
  background-color: #2f7351 !important;
  font-size: 14px !important;
  color: white !important;
  width: 10px !important;
  margin-bottom: 16px !important;

  &:hover {
    background-color: darken(#2f7351, 10%) !important; // darken the color by 10% on hover
  }
}

/* radius-classes*/
.border-radius-16 {
  border-radius: 16px !important;
}

/* row-gap-classes*/
.row-gap-6 {
  row-gap: 6px !important;
}

.pointer-auto {
  pointer-events: auto !important;
}
.three-line-trim {
  display: -webkit-box;

  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}
.one-line-trim {
  display: -webkit-box;

  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}
.hoverable-block {
  background-color: transparent !important;
  transition: all !important;
  transition-duration: 300ms !important;
  &:hover {
    background-color: #f0f8ff !important;
    & .opacity-0 {
      opacity: 1 !important;
    }
  }
}

.disable-d-none {
  & .d-none {
    display: flex !important;
  }
  & .opacity-0 {
    opacity: 1 !important;
  }
}

.bg-right-slider {
  background-color: var(--col-right-slider);
}

.accordionSummary .MuiAccordionSummary-content {
  margin: 0px !important;
  width: 100% !important;
}
.apps-height {
  height: 280px !important;
}

.projectSliderHeading {
  @apply uppercase text-base text-black/40 leading-5;
}

.create-org-button {
  @apply leading-5 !tracking-wider !px-6 !py-3 !text-sm;
}
