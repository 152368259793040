.project_name {
  &:hover .opacity-0 {
    opacity: 1 !important;
  }
}
.status-type {
  color: #ff9800 !important;
}

.list-item .always-visible {
  display: block;
}

.list-item .hover-visible {
  display: none;
}

.list-item:hover .always-visible {
  display: none;
}

.list-item:hover .hover-visible {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hover-visible {
  display: none !important;
}

.org-row:hover .hover-visible {
  display: flex !important;
}
