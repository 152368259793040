//font color
.fc-prime {
  color: var(--col-prime) !important;
}
.fc-dark {
  color: var(--col-dark) !important;
}
.fc-white {
  color: var(--col-white) !important;
}
.fc-grey {
  color: var(--col-grey-one) !important;
}

//font family
.ff-head {
  font-family: 'Inter', sans-serif;
}
.ff-body,
body {
  font-family: 'Inter', sans-serif;
}
