.timezone-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.scrollbar {
  scrollbar-width: 5px;
}
.org-rename-input-field div input {
  padding: 0px !important;
}

.all-plans {
  max-width: 1200px !important;
  width: 800px !important;
  @media (max-width: 1630px) {
    width: auto !important;
  }
  // width: fit-content;
}
