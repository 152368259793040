.variableslider__response {
  &__responsevalue {
    border: 1px solid lightgray;
    min-height: 300px;
  }
}

.variableslider__accordion {
  &__variableinput {
    min-height: 100px;
  }
}
