.color-white {
  color: var(--col-white) !important;
}
.color-primary {
  color: var(--col-primary) !important;
}
.bg-primary {
  background-color: var(--col-primary) !important;
}
.bg-error {
  background-color: var(--col-error) !important;
}
.color-black {
  color: var(--col-dark) !important;
}

.color-dark-lt {
  color: var(--col-dark-lite);
}
.color-grey-one {
  color: var(--col-grey-one);
}
.bg-white {
  background-color: #fff !important;
}

.bg-black {
  background-color: var(--col-dark) !important;
}

.bg-grey-white {
  background-color: var(--col-grey-white) !important;
}

.bg-workspace {
  background-color: var(--col-grey-background) !important;
}
// notes background color
.notes-bg {
  background-color: var(--notes-bg-color) !important;
}

// logs Slider color

.nav-bg-pause {
  background-color: var(--navbar-bg-pause) !important;
}
// delete
.nav-bg-delete {
  background-color: var(--navbar-bg-delete) !important;
}
.bg-primary-20 {
  background-color: var(--col-primary-20) !important;
}

.color-aftersignup {
  color: var(--col-after-signup) !important;
}

.bg-danger-lite {
  background-color: var(--col-danger-lite) !important;
}
