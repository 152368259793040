.service-description {
  color: rgb(107, 111, 118);
  display: -webkit-box;
  font-size: 0.8125rem !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}
.service-block {
  cursor: pointer;
  padding: 16px !important;
  height: 150px;
  min-width: 200px;
  &__enabled {
    background-color: #e0f8e0 !important;
  }

  &:hover {
    background-color: #dedede !important;
    & .opacity-0 {
      opacity: 1 !important;
    }
  }
  &__no-hover {
    &:hover {
      background-color: transparent !important;
      cursor: unset !important;
    }
  }
  .service-title {
    word-wrap: pre-wrap;
    text-overflow: ellipsis !important;
    overflow-x: hidden;
  }
  &__auth-show {
    height: 100px !important;
  }
  &__selected {
    border: 4px solid #f0f8ff !important;
  }
  &__no-description {
    height: 64px !important;
  }
}

.large-icon {
  width: 32px;
  height: 32px;
}
.small-icon {
  width: 20px;
  height: 20px;
}

.preview-section-container {
  max-width: 760px;
  width: 100%;
  .enabled-flow-box {
    width: 100% !important;
    .label {
      width: 100%;
      color: white;
      height: 24px;
    }

    &__error {
      .label {
        background-color: rgb(139, 0, 0, 0.1);
        color: rgb(139, 0, 0);
      }
    }
    &__warning {
      .label {
        background-color: #f7f7f7;
        color: black;
      }
    }
  }
}

.single-combination-grid-item {
  grid-column: span 12;
}
.sticky-top-right {
  position: absolute;
  top: 16px;
  right: 16px;
}
.flow-footer {
  border-top: 0.1px solid rgb(233, 231, 226);
}
