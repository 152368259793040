.templates_box {
  cursor: pointer !important;
  height: 300px;
  gap: 12px;
  &:hover {
    background-color: rgba(30, 117, 186, 0.02);
  }
  background: rgba(255, 255, 255, 1);

  border: 0.2px solid #ccc;
}
