.slider {
  &__flow {
    width: var(--script-slider-w);
  }
}

.script_slider {
  background-color: var(--col-flow-slider) !important;
  overflow-y: auto;

  .slider_collapsed {
    border-right: 1px solid #161616 !important;
  }

  .normal-width {
    width: var(--script-slider-w);
  }

  .large-width {
    width: calc(var(--script-slider-w) + 150px);
  }
}

.script_slider_large {
  background-color: var(--col-flow-slider);

  .normal-width,
  .large-width {
    width: calc(var(--script-slider-w) + 150px);
  }
}

.developer-slider-box {
  background-color: #fff;
  overflow-y: auto;
}

.plug-slider-box {
  background-color: var(--col-log-slider);
}

.logs-slider-responsive {
  min-width: 240px !important;
  max-width: 350px !important;
  // background-color: var(--col-right-slider);
  background-color: var(--col-main-container);
}

.logs_slider {
  .slider_collapsed {
    border-right: 1px solid rgba(123, 127, 130, 0.3);
    display: none !important;
  }

  .slider_expanded {
    padding: 5px;
    margin-right: 10px;
  }

  // overflow-y: auto;
  .normal-width {
    width: var(--log-slider-w);
  }

  .large-width {
    width: calc(var(--log-slider-w) + 195px);
  }
}

.project-slider {
  background-color: var(--col-project-slider);
}

.sliderbox {
  background-color: var(--col-flow-slider) !important;
}

.masterslider {
  position: absolute !important;
  top: 0;
  right: 0;
  // z-index: 21;
}

.toggle_button_log_box {
  .icon_button {
    height: 26px;
    width: 26px;
    border-radius: 50%;
    background-color: #fff !important;
    border: 1px solid rgba(242, 240, 233, 1) !important;
  }
}

.toggle_button_log_box {
  right: -28px !important;
  top: 20px !important;
}

.toggle_button_script_slider {
  position: absolute;
  height: 0;
  width: 0;
  right: 12px;
  top: 24px;
  z-index: 40 !important;

  .icon_button {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background-color: #fff !important;
    border: 1px solid var(--col-flow-slider) !important;
  }
}

.slider_collapsed {
  width: 16px !important;
}

.toggle_button_plugin_slider {
  position: absolute;
  height: 0;
  width: 0;
  left: -12px;
  top: 20px;

  .icon_button {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background-color: white !important;
    border: 1px solid var(--col-flow-slider) !important;
  }
}

.transform-180 {
  transform: rotate(180deg);
}

.text_button_log_box {
  position: absolute;
  height: 0;
  width: 0;
  right: -2px;
  top: 10px;
  z-index: 100 !important;
}

.search-bar-container {
  &__collapsed {
    background: rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    border-radius: 4px;
    z-index: 1000;
    margin-left: 10px;
    width: calc(var(--script-slider-w) - 40px);

    .global-search-bar {
      height: 20px;
      padding: 4px 0px 0px 4px !important;
      color: white !important;
      outline: none !important;
      z-index: 1000;
    }
  }

  &__open {
    background: rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    border-radius: 4px;
    z-index: 1000;
    width: calc(var(--script-slider-w) - 40px);

    .global-search-bar {
      height: 20px;
      padding: 4px 0px 0px 4px !important;
      color: white !important;
      outline: none !important;
      z-index: 1000;
    }
  }
}

.plguin_dashboard_slider {
  background-color: var(--col-log-slider) !important;

  border-right: 1px solid #ccc;

  .normal-width {
    width: var(--template-slider-w) !important;
  }

  .large-width {
    width: calc(var(--template-slider-w) + 150px);
  }
}

.plguin_dashboard_slider_large {
  background-color: var(--col-log-slider) !important;

  .normal-width,
  .large-width {
    width: calc(var(--template-slider-w) + 150px);
  }
}

.all_plugin_large {
  background-color: var(--col-dh-slider);

  .normal-width,
  .large-width {
    width: calc(var(--embed-slider-w) + 150px);
  }
}

.all_plugin {
  background-color: var(--col-dh-slider);

  .normal-width {
    width: var(--embed-slider-w) !important;
  }

  .large-width {
    width: calc(var(--embed-slider-w) + 150px);
  }
}

.toggle_button_allplugin {
  position: absolute;
  top: 11px;
  left: 5px;

  .icon_button {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background-color: white !important;
    border: 1px solid white !important;
  }
}

.plugindashboard-slider-open {
  left: calc(var(--template-slider-w) + 5px);
}

.toggle_button_plugin_dashboard {
  position: absolute;
  height: 0;
  width: 0;
  left: 16px;
  top: 12px;

  .icon_button {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background-color: #fff !important;
    border: 1px solid var(--col-flow-slider) !important;
  }
}

.toggle_button_dashboard_slider {
  position: absolute;
  height: 0;
  width: 0;
  left: -12px;
  top: 20px;

  .icon_button {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background-color: var(--col-log-slider) !important;
    border: 1px solid white !important;
  }
}

.events-page {
  background: var(--col-slider-layout);
  border-left: 2px solid rgba(213, 217, 220, 1);
}

.service-slider {
  width: 300px;
}
.drawer {
  position: fixed;
  top: 100px;
  height: 70vh;
  left: 0;
  box-shadow: 2px 0 5px rgba(45, 45, 45, 0.352);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 50;

  #chat-with-ai-box {
    display: none;
  }

  #side-panel {
    margin-bottom: 0;
  }
}
.drawer-open {
  transform: translateX(0) !important;
}
