.project_slider,
.project_slider__full {
  background-color: var(--col-project-slider) !important;
}

.project_slider {
  // width: var(--project-slider-w);

  .project-page-cont {
    width: var(--project-slider-w) !important;
    // border-right: 0.2px solid #ccc;
  }

  .chat-with-ai {
    width: 350px !important;
  }
}

.slider_collapsed {
  display: 16px !important;
}

.project-layout-color {
  background-color: var(--col-project-slider) !important;
}
.lists-when-dropdown-close {
  opacity: 0;
  visibility: hidden;

  &.visible {
    transition: opacity cubic-bezier(0.19, 1, 0.22, 1) 2s, visibility cubic-bezier(0.19, 1, 0.22, 1) 2s;
    opacity: 1;
    visibility: visible;
  }
}
