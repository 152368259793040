.suggestion-box {
  background-color: var(--col-right-slider);
  border: 0.2px solid #ccc;
  width: var(--suggestion-box-w);
  z-index: 999;
  height: 100vh;
  top: 0;
  padding-left: 8px !important;
  padding-right: 8px !important ;
  &__icon {
    height: 24px !important;
    width: 24px !important;
  }

  &__chip__icon {
    height: 24px !important;
    width: 24px !important;
  }

  &__parent {
    height: 0 !important;
    width: 0 !important;
  }

  &__icon-insidechip {
    // position: absolute !important;
    // top: 50% !important;
    // left: 0% !important;
    // transform: translate(0%, -50%);
    width: 14px !important;
    height: 14px !important;
  }
}
@media (max-width: 1200px) {
  .suggestion-box {
    width: var(--suggestion-box-w-small-screen);
  }
}
.value {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Number of lines to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  /* or nowrap depending on your needs */
}

.usedvarslider-block {
  &__title {
    width: 30%;
  }

  &__value {
    width: 50%;
  }
}

.editable_div {
  overflow: auto !important;
}

.editable_div:empty:before {
  content: attr(data-text);
  color: #999999;
}

.variable-value {
  word-break: break-all;
}

.slider-button {
  opacity: 0;
}

.input-parent {
  &:hover .slider-button {
    opacity: 1 !important;
  }

  &:hover {
    .opacity-0 {
      opacity: 1 !important;
    }
  }
}
.input-parent-withborder {
  &:focus-within {
    border: 1px solid var(--col-primary);
  }
}

.list-item {
  &:hover .right-button {
    opacity: 1 !important;
    display: inline-flex !important;
  }

  .right-button {
    opacity: 0 !important;
    display: none !important;
  }
}

.hidden {
  display: none !important;
  height: 0 !important;
  overflow-y: hidden;
}

.margin-step-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: calc(100vw - var(--suggestion-box-w));
}

.margin-step {
  position: fixed;
  top: 0;
  right: var(--right-slider-w);
}
@media (max-width: 1200px) {
  .margin-step {
    left: 0;
  }
}
.slider-left-zero {
  position: fixed;
  top: 0;
  left: 0 !important;
}

.slider-left-zero {
  position: fixed;
  top: 0;
  left: 0 !important;
}

.margin-step-and-ai {
  position: fixed;
  top: 0;
  right: var(--alert-slider-w);
}

.margin-step-and-chatbot {
  position: fixed;
  top: 0;
  right: calc(var(--alert-slider-w) + var(--right-slider-w));
}

.suggestion_slider_right {
  position: fixed;
  right: 0;
  transition: 0.2s;
}

.right {
  position: absolute !important;
  right: 30vw;
  transition: 0.2s;
}

.content-right {
  position: fixed !important;
  right: calc(30vw + var(--right-slider-w));
  z-index: 1000000000000;
  width: calc(102vw - 30vw - var(--right-slider-w));
}

.swipe-left {
  right: calc(30vw + var(--right-slider-w));
  width: calc(100vw - var(--right-slider-w) - 30vw);
  position: fixed !important;
}
.ellipses-revert {
  white-space: nowrap;
  width: fit-content;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl !important;
}

.show-value {
  max-width: 150px !important;
}
