@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');

@import './var';
@import './text';
@import './reset';
@import './size';
@import '../utilities/utility';
@import '../utilities/form';
@import '../utilities/button';
@import '../utilities/flex';
@import '../utilities/margin';
@import '../utilities/padding';
@import '../utilities/color';
@import '../utilities/alignment';
@import '../utilities/sizing';
@import '../layout/components';
