.parent-box {
  padding: 40px 100px !important;
  background-color: #f6f4ee;
  height: 100vh;
}

.input-bg-white {
  .MuiInputBase-root {
    background-color: white;
  }
}
